import { HTTP } from './http';

class Base {
    public errorHandle(error: HttpException): Promise<ApiResult> {
        return Promise.resolve({ error });
    }

    public successHandle(data: unknown): Promise<ApiResult> {
        return Promise.resolve({ data });
    }
}

class Accounts extends Base {
    constructor() {
        super();
    }

    public async login(type: 'pwd' | 'resume', payload: { account?: string, password?: string, token?: string }) {
        return HTTP.send('/api/cemetapub/ops/v1/account/login', 'post', { data: { type, payload } }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }

    public async getCompanyUser(companyId: string) {
        return HTTP.send('/api/cemeta/ops/v1/account/user', 'get', { params: { companyId } }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }
}

class Configs extends Base {
    constructor() {
        super();
    }

    public async setConfig(key: 'account.registerType', value: unknown) {
        return HTTP.send('/api/cemeta/ops/v1/config', 'post', { data: { config: key, value } }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }
}

class Services extends Base {
    constructor() {
        super();
    }

    public async submitServer(server: { id?: string, name?: string, type: string, address: string, weight: number, receiveReq: boolean }) {
        return HTTP.send('/api/cemeta/ops/v1/server', 'post', { data: server }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }

    public async getServerList(option?: { type?: string, address?: string, skip?: number, limit?: number }) {
        return HTTP.send('/api/cemeta/ops/v1/server', 'get', { params: option }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }

    public async updateServer(serverId: string, update: { receiveReq?: boolean }) {
        return HTTP.send(`/api/cemeta/ops/v1/server/${serverId}`, 'post', { data: update }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }

    public async deleteServer(serverIds: Array<string>) {
        return HTTP.send('/api/cemeta/ops/v1/server', 'delete', { data: { ids: serverIds } }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }
}

class Companys extends Base {
    constructor() {
        super();
    }

    public async addCompany(company: { name: string, phone: Array<string> }) {
        return HTTP.send('/api/cemeta/ops/v1/company', 'post', { data: company }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }

    public async getCompanyList(option?: { skip?: number, limit?: number, keyword?: string }) {
        return HTTP.send('/api/cemeta/ops/v1/company', 'get', { params: option }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }

    public async updateCompany(companyId: string, update: { phone?: Array<string>, lock?: boolean }) {
        return HTTP.send(`/api/cemeta/ops/v1/company/${companyId}`, 'post', { data: update }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }

    public async deleteCompany(companyId: string) {
        return HTTP.send(`/api/cemeta/ops/v1/company/${companyId}`, 'delete').then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }
}

class Tickets extends Base {
    constructor() {
        super();
    }

    public async createTicket(info: { appId: string, phone: string, word?: number, image?: number }) {
        return HTTP.send('/api/cemeta/ops/v1/ticket', 'post', { data: info }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }

    public async getTicketList(option?: { skip?: number, limit?: number, phone?: string }) {
        return HTTP.send('/api/cemeta/ops/v1/ticket', 'get', { params: option }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }

    public async deleteTicket(ticketIds: Array<string>) {
        return HTTP.send('/api/cemeta/ops/v1/ticket', 'delete', { data: { ids: ticketIds } }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }
}

class Applications extends Base {
    constructor() {
        super();
    }

    public async getAppList(option?: { hasAuth?: 'yes', skip?: number, limit?: number }) {
        return HTTP.send('/api/cemeta/ops/v1/application', 'get', { params: option }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }

    public async updateSetting(appId: string, option: Record<string, boolean>) {
        return HTTP.send(`/api/cemeta/ops/v1/application/${appId}/setting`, 'post', { data: option }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }

    public async getAppAuthData(appId: string, type: 'user' | 'company') {
        return HTTP.send(`/api/cemeta/ops/v1/application/${appId}/auth`, 'get', { params: { type } }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }

    public async setAppAuth(appId: string, type: 'user' | 'company', option: { companyIds?: Array<string>, phones?: Array<string> }) {
        return HTTP.send(`/api/cemeta/ops/v1/application/${appId}/auth`, 'post', { data: { type, ...option } }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }

    public async getSkuList(query: { appId: string, companyId: string }, option?: { skip?: number, limit?: number }) {
        return HTTP.send('/api/cemeta/ops/v1/application/data/sku', 'get', { params: { ...query, ...option } }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }

    public async updateSku(skuId: string, type: string, data: unknown) {
        return HTTP.send('/api/cemeta/ops/v1/application/data/sku', 'put', { data: { id: skuId, type, data } }, { timeout: 50 * 1000 }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }
}

export default {
    Account: new Accounts(),
    Config: new Configs(),
    Service: new Services(),
    Company: new Companys(),
    Ticket: new Tickets(),
    Application: new Applications()
};
