import { createRouter, createWebHashHistory, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';


const routes: Array<RouteRecordRaw> = [{
    path: '/',
    redirect: '/login'
}, {
    path: '/login',
    meta: { title: '登录' },
    component: () => import(/* webpackChunkName: 'login' */ '../views/login.vue')
}, {
    path: '/ops',
    redirect: '/ops/index',
    component: () => import(/* webpackChunkName: 'layout' */ '../views/layout/index.vue'),
    children: [
        /** 首页 */
        {
            path: '/ops/index',
            meta: { title: '首页' },
            component: () => import(/* webpackChunkName: 'index' */ '../views/home.vue')
        },
        /** 资源管理 */
        {
            path: '/ops/service',
            meta: { title: '服务器管理', group: '资源管理' },
            component: () => import(/* webpackChunkName: 'server' */ '../views/resource/server.vue')
        },
        {
            path: '/ops/ticket',
            meta: { title: '兑换券管理', group: '资源管理' },
            component: () => import(/* webpackChunkName: 'ticket' */ '../views/resource/ticket.vue')
        },
        {
            path: '/ops/business-user',
            meta: { title: '企业管理', group: '资源管理' },
            component: () => import(/* webpackChunkName: 'company' */ '../views/resource/company.vue')
        },
        {
            path: '/ops/application',
            meta: { title: '应用管理', group: '资源管理' },
            component: () => import(/* webpackChunkName: 'application' */ '../views/resource/application.vue')
        },
        /** 数据管理 */
        {
            path: '/ops/data',
            meta: { title: 'SKU', group: '数据管理' },
            component: () => import(/* webpackChunkName: 'application' */ '../views/data/sku.vue')
        },
        /** 授权管理 */
        {
            path: '/ops/app-auth',
            meta: { title: '应用授权', group: '授权管理' },
            component: () => import(/* webpackChunkName: 'application' */ '../views/auth/application.vue')
        },
        /** 设置 */
        {
            path: '/ops/setting',
            meta: { title: '设置', group: '系统设置' },
            component: () => import(/* webpackChunkName: 'setting' */ '../views/setting/index.vue')
        }
    ]
}];


const router = createRouter({
    history: process.env.NODE_ENV === 'development' ? createWebHashHistory(process.env.BASE_URL) : createWebHistory(process.env.BASE_URL),
    routes
});


/**
 * 全局导航守卫
 */

/* 前置导航守卫 */
router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    // do something before next route
    next();
});

/* 后置导航守卫 */
router.afterEach((to: RouteLocationNormalized/*, from: RouteLocationNormalized, failure?: NavigationFailure | void*/) => {
    document.title = `CeMeta(ops) ${to.meta.title}`;
});

export default router;
