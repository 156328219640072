import { __awaiter, __generator } from "tslib";
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
export default defineComponent({
    setup: function () {
        var _this = this;
        var store = useStore();
        var loginByToken = function () { return __awaiter(_this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, store.dispatch('user/login', { type: 'resume' })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var locale = computed(function () { return zhCn; });
        return {
            loginByToken: loginByToken,
            locale: locale
        };
    },
    created: function () {
        var token = window.localStorage.getItem('cemeta_ops_token');
        if (token) {
            this.loginByToken();
        }
    }
});
